.tsc-calendar {
  display: flex;
  flex: 1;
  flex-direction: column;
  color:  #313232;
  background: #ECA914;
  padding: 0.8em;
}

.tsc-calendar__actions {
  display: flex;
  flex: 1;
  border-bottom: 0.075em solid darken(#ecf0f1, 5%);
}

.tsc-calendar__action {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color:  #313232;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

.tsc-calendar__action-element {
  font-size: 1.5em;
  padding: 0 1em;
  cursor: pointer;
}

.tsc-calendar__action-title {
  flex-grow: 2;
  padding: 1em;
  text-transform: uppercase;
}
