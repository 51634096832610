.tsc-day {
  margin: auto;
}

.tsc-day__title {
  color: #01304A;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  font-weight: 700;
  padding: 1em;
  text-transform: uppercase;
  max-width: 77px;
}
