.tsc-timeslot {
  display: flex;
  justify-content: center;
  padding: 1em;
  font-size: 0.9em;
  background-color:  #FFFFFF;
  transition:  background-color 0.5s, color 0.5s, box-shadow cubic-bezier(0.25, 0.8, 0.25, 1) 0.3s;
  border-radius: 0.25em;
  text-align: center;
  margin: 0.8em;
  font-family: 'Open Sans', sans-serif;
  color:  #313232;
  box-shadow: 0 0.0625em 0.188em rgba(0,0,0,0.12), 0 0.0625em 0.125em rgba(0,0,0,0.24);
  cursor: pointer;
}

.tsc-timeslot--selected {
  background-color: #050D7C;
  color: #FFFFFF;
}

.tsc-timeslot--disabled {
  background-color: #EEEDEB;
  color: #313232;
  cursor: not-allowed;
}
