.tsc-month {
  display: flex;
  flex-direction: column;
  background:  #ECA914;
}

.tsc-month__actions {
  display: flex;
  position: relative;
}

.tsc-month__action {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #050d7c;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

.tsc-month__action-element {
  font-size: 1.5em;
  padding: 0 1em;
  cursor: pointer;
}

.tsc-month__action-element--left {
  position: absolute;
  left: 0;
  top: 22px
}

.tsc-month__action-element--right {
  position: absolute;
  right: 0;
  top: 22px;
}

.tsc-month__action-title {
  flex-grow: 2;
  padding: 1em;
}
